body {
    &.login-page {
        background: rgba(0, 0, 0, 0) url(../../img/login-bg2.jpg) repeat scroll 0 0;
        background-size: cover;
    }
}

.login-panel {
    background: #fff none repeat scroll 0 0;
    padding: 2.5rem 2rem;
    -webkit-transform: translate(-50%, -50%);
    -moz-transform: translate(-50%, -50%);
    -o-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    width: 410px;
    left: 50%;
    position: fixed;
    top: 50%;
    transition: height 350ms ease-in-out, all 350ms ease-in-out;
    height: 26.1875rem;
    @include mq(xs) {
        width: 85%;
    }
    &.forgot-panel{
        transition: height 350ms ease-in-out, all 350ms ease-in-out;
        height:21.125rem;
    }
    .form-control{
        font-size: 1rem;
    }
}


.remember-password{
    label{
        padding-left: .5rem;
    }
}

.login-links{
    display: flex;
    justify-content: space-between;
}

.ah-email{
    position: relative;
    margin-bottom: 1.5625rem;
    .form-control{
        padding-left: 1.875rem;
    }
    &:after{
        content: "\f007";
        position: absolute;
        font-family: "Font Awesome 5 Free";
        font-weight: 900;
        font-size: 13px;
        left: .5rem;
        top: 50%;
        transform: translateY(-50%);
    }
}

.ah-passord{
    position: relative;
    margin-bottom: 1.5625rem;
    .form-control{
        padding-left: 1.875rem;
    }
    &:after{
        content: "\f023";
        position: absolute;
        font-family: "Font Awesome 5 Free";
        font-weight: 900;
        font-size: 13px;
        left: .5rem;
        top: 50%;
        transform: translateY(-50%);
    }
}


.forget-pass-link{
    font-size: .875rem;
}

.signup-link{
    text-align: center;
    margin-top: 26px;
    a{
        color: #0093e7;
        font-weight: 500;
        // text-transform: uppercase;
    }
}