.btn-primary{
 color: $white;
}

.btn{
 font-size: 1rem;
 padding: .1875rem .75rem;
 min-width: 5rem;
 transition: $btn-transition;
 border:0;
 transition: all 2s ease;
 &:hover{
  color: $white;
  background-image: linear-gradient(rgba(0, 0, 0, 0.4) 0 0);
  transition: all 2s ease;
 }
 // &:focus{
 //  color: $white;
 // }
 &:disabled{
  color: $white;
 }
}

.btn-icon{
 font-size: 1rem;
 padding-right: .3125rem;
}

.p-button{
 .p-button-label{
  color: $white !important;
 }
 .p-button-icon{
  color: $white !important;
 }
 &:focus{
  box-shadow: none;
 }
}

.btn-edit{
 background: $mdg-lightgray;
 color: $white;
}

.btn-export{
 background: $mdg-lightpurple;
 color: $white;
}

.btn-cancel{
 background: $mdg-lightred;
 color: $white;
}

.btn-clear-query{
 background: $mdg-orange;
 color: $white;
}
.btn-load-temp{
 background: $mdg-purple;
 color: $white;
}
.btn-save-temp{
 background: $mdg-green;
 color: $white;
}
.btn-view-table{
 background: $mdg-gray;
 color: $white;
}

.btn-delete{
 background: $mdg-delete;
 color: $white;
}

.btn-copy{
 background: $mdg-green;
 color: $white;
}

.btn-sm{
 font-size: .75rem;
}

.p-button{
    &.btn-secondary{
        background-color: #e1e1e1;
        border-color: #e1e1e1;
        .p-button-label{
            color: #000 !important;
        }
        &:hover, &:focus{
            background-color: #e6e6e6;
            border-color: #e4e4e4;
        }
    }
}