.gray-bg {
    background: $gray-light4;
    border: 1px solid $gray-light3;
    padding: 0.9375rem;
}

.white-box {
    background: $white;
    padding: 0 0 1rem 0;
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.05);
    font-size: .875rem;
}

.box-content {
    padding: 0 1rem;
    position: relative;
}

.box-title {
    background: $gray-light5;
    border-bottom: 1px solid #ababab;
    padding: 0.75rem;
    color: $body-color;
    font-size: 1.125rem;
    position: relative;
    font-weight: 300;
    text-transform: uppercase;
    // &:after{
    //  content:"";
    //  border-bottom:1px solid $gray-dark;
    //  position: absolute;
    //  bottom: 0;
    //  left:.75rem;
    //  right: 0;
    //  display: inline-block;
    //  width: 3.125rem;
    // }
}

.field-mapping-main {
    display: flex;
    align-items: center;
    .field-mapping {
        width: 90%;
        position: relative;
        i{
            position: absolute;
            right: 4px;
            top: 9px;
        }
    }
    a {
        margin-left: 10px;
    }
}

.field-mapping {
    background-color: #f6f6f6;
    border: 1px solid #e4e4e4;
    border-radius: 6px;
    color: #4a5265;
    line-height: 32px;
    padding: 0 1px 0 3px;
    span {
        // background-image: url("../../img/icon-set.png");
        // background-position: -95px -95px;
        // float: left;
        // height: 32px;
        // width: 27px;
        height: 20px;
        width: 20px;
        background: $mdgprimary;
        border-radius: 100%;
        display: inline-block;
        line-height: 20px;
        text-align: center;
        margin-right: 10px;
        font-weight: bold;
        color:#fff;
    }
}

.file-detail-link {
    float: right;
    position: relative;
    li {
        display: inline-block;
        position: relative;
        margin-right: 0px !important;
        font-size: .875rem;
        text-transform: capitalize;
        &::before {
            content: "";
            position: absolute;
            border-left: 1px solid $gray-light3;
            left: -1rem;
            top: -1rem;
            bottom: -1rem;
        }
        a {
            color: $mdgprimary !important;
            font-size: 0.8125rem;
            margin-left: 0.625rem;
        }
    }
}

.ah-title {
    font-size: .9375rem;
    border-bottom: 1px solid $gray-light3;
    padding-bottom: 0.3125rem;
}

.ah-fileupload {
    width: 100%;
    span{
        @include mq(sm) {
            width: 100%;
            display: block;
            margin-bottom: .625rem;
        }
    }
    .p-button {
        .p-button-icon-left{
          display: none;
        }
    }
    .ah-fileupload-choose {
        border: 1px solid $mdgprimary;
        width: 50%;
        line-height: 2.125rem;
        padding-left: 10px;
        font-size: 1rem;
        height: 2.125rem;
        font-size: .8125rem;
        @include mq(sm) {
            width: 100%;
        }
        .p-button-icon-left {
            display: none;
        }
    }
    .p-button-label{
        text-transform: uppercase;
        font-size: .875rem;
    }
}

.p-scrollpanel.custom-scroll {
    .p-scrollpanel-wrapper {
        border-right: 10px solid $mdgsecondary;
    }
    .p-scrollpanel-bar {
        background-color: $mdgprimary;
        opacity: 1;
        transition: background-color 0.2s;
        &:hover {
            background-color: $mdgprimary;
        }
    }
}

.record-arrows{
    i{
        color: $mdgprimary;
    }
}

.exceptation-modal{
    span{
        font-size: .875rem;
    }
    a{
        font-size: .875rem;
    }
}
