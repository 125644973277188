.ah-grid{
    .p-datatable{
        box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.05);
        background: $white;
        // border-top:2px solid $mdgprimary;
        // .p-inputtext{
        //     color: $gray-dark;
        // }
        .p-datatable-header{
            background: $white;
            border: 1px solid $gray-light;
            border-width: 1px 0 2px 0;
            padding: .75rem 1rem;
            .table-header{
                text-align: right;
                .p-input-icon-left{
                    .pi-search{
                        left: 0;
                        font-size: .75rem;
                        top: 1rem;
                        margin-top: -0.2rem;
                    }
                    .p-inputtext{
                        padding-left: 1.25rem;
                    }
                }
            }
        }
        .p-datatable-thead {
            tr{
                th{
                    // background: $white;
                    padding: .25rem 1rem;
                    font-size: 13px;
                    border:1px solid $gray-light;
                    border-width: 0 0 1px 0;
                    &.p-filter-column{
                        padding-top: 8px;
                        padding-bottom: 8px;
                        .p-column-filter{
                            width: 100%;
                            // padding: 0.4375rem 1.25rem 0.4375rem 0;
                            font-size: 0.875rem;
                            font-weight: 400;
                            line-height: 1.5;
                            &.p-multiselect {
                                .p-multiselect-label{
                                    padding: 0;
                                }
                            }
                        }
                        .p-inputtext{
                            padding: 0;
                            font-size: 13px;
                        }
                    }
                }
            }
        }
        .p-datatable-tbody{
            tr{
            &:hover{
                    &:nth-child(odd){
                        background: $gray-light2;
                    }
            }
            td{
                color:$gray-dark;
                padding: .375rem 1rem;
                font-size: 13px;
                    @media only screen and (max-width: 1366px) {
                        font-size: 12px;
                    }
                }
            }
        }
    }
}

// pagination

.p-paginator {
    font-size: .875rem;
    border-width: 0 !important;
    .p-paginator-pages {
        .p-paginator-page{
            &.p-highlight{
                background: $mdgprimary;
                border-color: $mdgprimary;
                color: $white;
            }
            &:focus{
                box-shadow: none;
            }
        }
    }
    .p-dropdown{
        background: transparent;
    }
}

// badge

.customer-badge{
    // background: #e6e9ee;
    padding: 0 5px;
    display: block;
    border-radius: 0.25em;
    @include mq(xl) {
        display: inline-block;
        padding:0;
    }
    // &.completed{
    //     border-left: 3px solid #00970C;
    // }
    // &.deleting{
    //     border-left: 3px solid #D11A2A;
    // }
    &.error{
        background: #d9534f;
        color: #fff !important;
    }
    &.deleted{
        background: #d9534f;
        color: #fff !important;
    }
    // &.exporting{
    //     border-left: 3px solid #9972b5;
    // }
    // &.incomplete{
    //     border-left: 3px solid #667F9E;
    // }
    // &.processing{
    //     border-left: 3px solid #ffff00;
    // }
    // &.queued{
    //     border-left: 3px solid #FF7F91;
    // }
    // &.generatereport{
    //     border-left: 3px solid #9acd32;
    // }
    // &.undone{
    //     border-left: 3px solid #708090;
    // }
    // &.importing{
    //     border-left: 3px solid #1e73be;
    // }
    // &.inactive{
    //     border-left: 3px solid #d3d3d3;
    // }
    // &.fordeletion{
    //     border-left: 3px solid #ff6347;
    // }
    // &.exception{
    //     border-left: 3px solid #696969;
    // }
    // &.active{
    //     border-left: 3px solid #00970C;
    // }
    // &.inactive{
    //     border-left: 3px solid #FD0000;
    // }
}


.ah-table{
    .datatable-page{
        &.p-datatable {
            .p-datatable-wrapper{
                border-top: 0;
            }
        }
    }
    .p-datatable-thead{
        tr{
            th{
                padding: .5rem 1rem;
                font-size: 13px;
            }
        }
    }
    .p-datatable-tbody{
        tr{
            td{
                padding: .3125rem 1rem;
                font-size: .875rem;
            }
        }
    }
}

.upload-table{
    table{
        tbody{
            tr{
                td{
                    @include mq(xl) {
                        width: 100%;
                        display: block;
                        word-break: break-word;
                    }
                }
            }
        }
    }
}

.p-datatable {
    .p-datatable-tbody {
        tr {
            td {
                font-size: 13px;
                .p-row-toggler{
                    width: auto;
                    height: auto;
                    &:focus{
                        box-shadow: none;
                    }
                    .p-row-toggler-icon{
                        &.pi-chevron-right{
                            &:before{
                                content: "\f055";
                                font-family: "Font Awesome 5 Free";
                                font-weight: 900;
                                color:$ascent1;
                            }
                        }
                        &.pi-chevron-down{
                            &:before{
                                content: "\f056";
                                font-family: "Font Awesome 5 Free";
                                font-weight: 900;
                                color:$ascent2;
                            }
                        }
                    }
                }
            }
        }
    }
}

.filter-table{
    .p-datatable{
        border-top:0;
        .p-datatable-thead{
            tr{
                th{
                    border:1px solid $gray-light;
                    border-width: 1px 0 1px 0;
                }
            }
        }
        .p-datatable-tbody {
            tr{
                td{
                    padding: 0 1rem;
                }
            }
        }
    }
}

.ah-nested-datatable{
    .datatable-responsive-demo .p-datatable-responsive-demo .p-datatable-tbody > tr > td .p-column-title {
        display: block;
    }
    .p-datatable{
        border-top:0;
    }
}

.datatable-responsive-demo .p-datatable-responsive-demo .p-datatable-tbody > tr > td .p-column-title {
 display: none;
}

@include mq(xl)  {
 .datatable-responsive-demo .p-datatable.p-datatable-responsive-demo .p-datatable-thead > tr > th,
 .datatable-responsive-demo .p-datatable.p-datatable-responsive-demo .p-datatable-tfoot > tr > td {
     display: none !important;
 }

 .datatable-responsive-demo .p-datatable.p-datatable-responsive-demo .p-datatable-tbody > tr > td {
     text-align: left;
     display: block;
     width: 100%;
     float: left;
     clear: left;
     border: 0 none;
 }

 .datatable-responsive-demo .p-datatable.p-datatable-responsive-demo .p-datatable-tbody > tr > td .p-column-title {
     padding: .4rem;
     width: 40%;
     display: inline-block;
     margin: -.4em 1em -.4em -.4rem;
     font-weight: bold;
 }

 .datatable-responsive-demo .p-datatable.p-datatable-responsive-demo .p-datatable-tbody > tr > td .p-column-desc {
    width: 55%;
    display: inline-block;
    vertical-align: top;
}

 .datatable-responsive-demo .p-datatable.p-datatable-responsive-demo .p-datatable-tbody > tr > td:last-child {
     border-bottom: 1px solid var(--surface-d);
 }
}

.text-ellipsis{
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100%;
    display: inline-block;
    @include mq(xl)  {
        width: 50%;
    }
}

.ah-nested-datatable{
    .p-datatable{
        .p-datatable-tbody{
            td{
                vertical-align: bottom;
                .p-inputtext{
                    padding: 0;
                }
            }
        }
    }
    table{
        tbody{
            td{
                &:nth-child(3){
                    color: $mdgprimary !important;
                }
                &:nth-child(5){
                    color: #e05d6f !important;
                    .p-inputtext{
                        width: 100%;
                    }
                }
                .p-togglebutton{
                    &.p-button{
                        border: 0;
                        background-color: #e1e1e1;
                        border-color: #e1e1e1;
                        .p-button-label{
                            color: #363537 !important;
                        }
                        &.p-highlight{
                            background-color: $mdgprimary;
                            border-color: $mdgprimary;
                            .p-button-label{
                                color: #fff !important;
                            }
                        }
                    }
                }
            }
        }
    }
    .p-datatable-resizable {
        .p-datatable-tbody {
            tr{
                td{
                    overflow: initial;
                }
            }
        }
    }
}

.table-word-break{
    word-break: break-word;
}

.ah-nested-table{
    padding: 0 5.625rem;
    .p-datatable{
        .p-datatable-thead{
            display: none;
        }
    }
}

.no-nesting-row{
    .p-row-toggler-icon{
        display: none;
    }
}

.dynamic-datatable{
    .p-datatable {
        .p-datatable-tbody {
            tr {
                td{
                    word-break: break-word;
                }
            }
        }
    }
}

.p-treetable {
    .p-treetable-header{
        background: #fff;
        padding: .3125rem 1rem;
        border-width:0;
    }
    .p-treetable-thead{
        tr{
            th{
                font-size: .875rem;
            }
        }
    }
    .p-treetable-tbody {
        tr{
            td{
                padding: .375rem 1rem;
                font-size: .875rem;
                &:first-child{
                    word-break: break-word;
                }
            }
        }
    }
}

.excel-file-icon{
    font-size: 1.125rem;
}

.output-table{
    .p-datatable-tbody{
        tr{
            td{
                &:nth-child(5){
                    label{
                        color: #d9534f !important;
                    }
                }
            }
        }
    }
}

.hideExpandBtn{
    .p-row-toggler{
        display: none;
    }
}

.fitmode-table{
    .p-datatable {
        .p-datatable-tbody {
            tr{
                td{
                    word-break: break-word;
                }
            }
        }
    }
}

.p-datatable {
    .p-datatable-thead {
        tr{
            th{
                .form-control{
                    padding-right: 0;
                }
            }
        }
    }
}

.p-datatable .p-column-resizer, .p-treetable .p-column-resizer {
    display: block;
    position: absolute !important;
    right: 0;
    margin: 0;
    width: 0;
    height: 60%;
    padding: 0px;
    cursor: col-resize;
    bottom: 0;
    top: 50%;
    transform: translateY(-50%);
}

// sorting icon change
.p-sortable-column-icon{
    cursor: pointer;
    pointer-events:all;
    &.pi-sort-alt:before, &.pi-sort-amount-down:before {
        content: "\e906";
        font-family: 'primeicons';
    }
    &.pi-sort-amount-up-alt:before {
        content: "\f0de";
        font-family: "Font Awesome 5 Free";
        font-weight: 900;
        vertical-align: -webkit-baseline-middle;
        line-height: 6px;
        font-size: 21px;
    }
}

.load-temp-desc{
    &:hover{
        color: initial;
    }
}

// modal popup datatable css
.modal-table{
    &.p-datatable {
        .p-datatable-thead{
            tr{
                th{
                    font-size: .875rem;
                }
            }
        }
        .p-datatable-tbody {
            tr{
                td{
                    padding:.3125rem 1rem;
                }
            }
        }
    }
}

.table-search-mobile{
    @include mq(sm) {
        text-align: left;
    }
    span{
        @include mq(sm) {
            width: 75%;
        }
    }
}
/* TreeTableDemo.css */

.treetable-responsive-demo .sm-visible {
    display: none;
}

@media screen and (max-width: 40em) {
    .treetable-responsive-demo .sm-invisible {
        display: none;
    }

    .treetable-responsive-demo .sm-visible {
        display: inline;
        margin-right: .5rem;
    }
}

.p-datatable .p-datatable-thead tr th.p-resizable-column{
    pointer-events:none;
}