.mdg-confiration-main{
 text-align: center;
 .card{
  p{
   font-size: 16px;
  }
 }
 .p-float-label > label{
  left: 0;
 }
}

.mdg-confirmation-padding{
 padding: 100px 0;
}

.email-icon{
 display: flex;
 width: 75px;
 height: 75px;
 border-radius: 100px;
 background: #fff;
 color: $mdgprimary !important;
 font-size: 30px;
 padding: 10px;
 align-items: center;
 justify-content: center;
 margin: 0 auto 30px;
 border: 1px solid #008aff;
}

.popup-conformation{
 i{
  font-size: 50px;
  color: red;
  margin-bottom: 16px;
 }
 p{
  font-size: 14px;
 }
}