.p-fileupload {
  .p-fileupload-content{
    padding: 0 .625rem;
    border:0;
    margin-top: .9375rem;
  }
  .p-fileupload-buttonbar {
    padding: 0;
    background: transparent;
    border: 0;
    .p-fileupload-choose {
      height: 2.125rem;
      &.p-button {
        border-radius: 0;
        margin-right: 0;
      }
    }
  }
}

.p-fileupload-row{
  font-size: .875rem;
}

.browse-file{
  display: flex;
  align-items: center;
  .browse-file-name{
    width: 30%;
    text-align: left;
    word-break: break-all;
    @media screen and (max-width: 401px) {
      width: 50%;
    }
  }
  .browse-file-size{
    width: 100%;
    text-align: left;
    display: flex;
    align-items: center;
    margin-left: .9375rem;
    @media screen and (max-width: 520px) {
      width: 50%;
    }
    button{
      margin-left: .5rem;
      @media screen and (max-width: 520px) {
        margin-left: 0;
        display: block;
        margin-bottom: .625rem;
      }
    }
    @include mq(md) {
      display: block;
    }
    .p-progressbar{
      margin-left: auto;
      height: 1.5625rem;
      width: 46%;
      @include mq(md) {
        width: 100%;
        margin-top: .625rem;
      }
    }
    .p-tag{
      @media screen and (max-width: 520px){
        margin-bottom: .625rem;
      }
      &.p-tag-warning{
        border-radius: 0;
        font-weight: 400;
        font-size: 1rem;
        padding:.1875rem .75rem !important;
      }
    }
  }
}
