.ah-boxshadow{
 box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.05);
 background: $white;
 .box-title{
   text-transform: initial !important;
   font-weight: 400;
 }
 .ah-box-content{
   padding: .75rem;
 }
}

.mdg-wordbreak{
  word-break: break-all;
}
.select-code-info {
  background: #ffe7e6;
  border-width: 0 0 0 6px;
  color: #ff5757;
  padding: 5px 10px;
  display: flex;
  align-items: center;
  font-size: 14px;

  i {
    color: #ff5757;
  }
}

.mdg-note{
  background: #f8ecb5;
  padding: 8px 8px;
  margin-bottom: 10px;
  border: 1px solid #d9c569;
  h6{
    font-size: 13px;
    font-weight: 500;
    margin-bottom: 0;
  }
}