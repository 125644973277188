.mdg-font-icons{
 i, .fa{
  font-size: 20px !important;
 }
}

.mdg-accordn-header{
 font-size: 1.125rem;
 font-weight: 500;
}

.data-search-content{
 p{
  font-size: 13px;
 }
 .box-title{
  font-weight: 500;
  text-transform: initial;
 }
 .box-content{
  box-shadow: 0 0 10px 0 rgba(0,0,0,.05);
  span{
   font-size: 14px;
  }
  .p-togglebutton.p-button{
   border: 0;
   background: transparent;
  }
 }
}

.data-search-icons{
 float: right;
 margin-top: 6px;
}

.mdg-toggle-btn.p-togglebutton.p-button.p-highlight,
.mdg-toggle-btn.p-togglebutton.p-button:not(.p-disabled):not(.p-highlight):hover {
 background: transparent;
 border-color: transparent;
 border: 0;
}

.mdg-toggle-btn{
  padding: 0.25rem 0.1rem !important;
}

.tile{
 box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
 padding: 15px;
 h3 {
  font-size: 36px;
  color: #575757;
  font-weight: 900;
  margin-top: 5px;
}
}

.datasearch-main{
 .form-control-border{
  border-radius: 0;
 }
 .p-inputtext, .p-multiselect .p-multiselect-label{
  padding: .3125rem 0.5rem;
  font-size: 14px;
 }
 .box-title{
   font-weight: 700;
 }
}

.mdg-inline-block-main{
 display: inline-block;
 vertical-align: middle;
 margin-right: 10px;
 &:last-child{
  margin-right: 0;
 }
}

.mdg-inline-block{
 display: inline-block;
 vertical-align: middle;
 margin-right: 7px;
 margin-bottom: 1.5rem;
 &:last-child{
  margin-right: 0;
 }
}

.data-add-icon{
 position: absolute;
 right: 0;
 top: 10px;
}

.box-copy {
 box-shadow: 0 0 10px 0 rgba(0,0,0,.05);
 padding: 1.5rem;
}

.datasearch-close-icon{
 position: absolute;
 right: 1.5625rem;
 top: 3px;
}

.p-button{
 &.btn {
  &.btn-primary{
   .p-button-label{
    color: #fff !important;
   }
  }
 }
}

.dsptable {
 .p-datatable-thead{
  display: none;
 }
}

.mdg-condition-text{
  word-break: break-word;
}
