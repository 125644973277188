.ah-tabs{
  .p-tabview {
 .p-tabview-nav{
  background: transparent;
  border:0;
  margin-bottom: .9375rem;
  li {
   position: relative;
   margin-right: .3125rem;

   &.p-tabview-nav-link-gray{
     a {
      background:gray !important;
     }
   }
   .p-tabview-nav-link{
     background: $mdgprimary;
     color: $white;
     border-width: 0;
     font-weight: 400;
     text-transform: uppercase;
     font-size: .875rem;
     padding: .75rem .875rem;
     border-top-right-radius: 0;
      border-top-left-radius: 0;
     &:not(.p-disabled):focus{
      box-shadow: none;
     }
   }  

   &.p-highlight{
    .p-tabview-nav-link{
     background: $mdgprimary;
     color: $white;
    }
    &:before{
     content: "\f0d7";
     font-family: "Font Awesome 5 Free";
     font-weight: 900;
     font-size: 32px;
     position: absolute;
     bottom: -29px;
     left: 0;
     right: 0;
     text-align: center;
     color: $mdgprimary;
    }
   }
  }
 }
}
}

.ah-tabs{
  .p-tabview {
    .p-tabview-panels{
      padding: 0;
      background: transparent;
    }
  }
}

.ah-tabs2{
  .p-tabview {
    .p-tabview-nav {
      border-bottom:1px solid $gray-light;
      border: 1px solid $gray-light;
      border-width: 0 0 2px 0;
      li.p-highlight {
        .p-tabview-nav-link{
          background-color: #ebf7fd;
          border-color: $gray-light;
          color: initial;
        }
      }
      li{
        .p-tabview-nav-link{
          // color: $body-color;
          border: 1px solid $gray-light;
          border-width: 0 0 2px 0;
          font-weight: 500;
          background: initial;
          border-radius: 0;
          &:not(.p-disabled):focus{
            box-shadow: none;
          }
        }
        &:not(.p-highlight):not(.p-disabled):hover {
          .p-tabview-nav-link{
            border-color: $gray-light;
            background-color: #ebf7fd;
          }
        }
      }
    }
    .p-tabview-panels{
      margin-top: .9375rem;
      background: transparent;
      color: $gray-dark;
      padding: 0;
    }
  }
}







