.upload-status-content{
 padding: 0;
 h5{
  border-bottom: 1px solid $gray-light3;
  padding: 0.75rem;
 }
}

.upload-table{
 table{
  tbody{
   tr{
    td{
     &:first-child{
      width: 7.1875rem;
     }
    }
   }
  }
 }
}

.tooltip-table{
 tbody{
  tr{
   td{
    font-size: .875rem;
    padding: .3125rem 0;
   }
  }
 }
}

.browse-modal{
 height: 2.125rem;
 font-size: .875rem;
 line-height: 1.5625rem;
}

.suppression-skip-tab{
 display: flex;
 justify-content: space-between;
 align-items: center;
 .p-tabview {
  .p-tabview-nav {
   li{
    &.p-tabview-nav-link-gray{
     &.p-highlight:before{
      color: #808080;
     }
    }
   }
  }
 }
}

.searchtext-color{
 color: #333 !important;
}