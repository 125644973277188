.p-datascroller {
 .p-datascroller-content {
  background: transparent;
  padding: 0;
 }

 &.p-datascroller-inline {
  .p-datascroller-list {
   li {
    border: 0;
    margin-bottom: 20px;

    .campaign-content-name {
     line-height: initial;
    }
   }
  }
 }
}