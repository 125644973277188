@import "/node_modules/bootstrap/scss/functions";

/// custom variable

///bootstrap override colors - brands
$mdgprimary: #0093e7;
$mdgsecondary: #e1e1e1;
$bodybg: #f7f7f7;
$bodycolor: #111;
$white:#fff ;
$mdgdanger: #dc3545;
$mdgsuccess: #3fc902;

$theme-colors: (
  "primary": $mdgprimary,
  "secondary": $mdgsecondary,
  "danger": $mdgdanger,
  "success": $mdgsuccess
);


// font family
$font-family-sans-serif: 'Roboto',
  sans-serif !default;

// gray colors
$gray-dark:#666666;
$gray-light:#c1c1c1;
$gray-light2:#f8f8f8;
$gray-light3:#e4e4e4;
$gray-light4:#f4f4f4;
$gray-light5:#f9f9f9;
$gray-light6:#d2d2d2;

$mdg-title:#333333;
$ascent1:#94c83c;
$ascent2:#e05d6f;
$ascent3:#9972b5;
$ascent4:#16a085;
$ascent5:#93c130;


// border
$border-color: $gray-light;

// button
$input-btn-padding-y: .4375rem !default; //7px
$input-btn-padding-x: 1.25rem !default; //1.25rem
$input-btn-font-size: 0.875rem !default; //14px
$input-btn-focus-width: 1px !default;
$btn-font-weight: 400 !default;
$btn-border-radius: 0 !default;
$btn-border-radius-lg: 0 !default;
$btn-border-radius-sm: 0 !default;
$btn-border-width: 0px !default;
$btn-link-disabled-color:$white !default;
$btn-link-color: $white !default;

$btn-transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;

$btn-hover-bg-shade-amount:       15%;
$btn-hover-bg-tint-amount:        15%;
$btn-active-bg-shade-amount:      20%;
$btn-active-bg-tint-amount:       20%;

// input
$input-bg: $white !default;


//headings
$h1-font-size: 1.875rem !default; //30px
$h2-font-size: 1.625rem !default; //26px
$h3-font-size: 1.375rem !default; //22px
$h4-font-size: 1.25rem !default; //20px
$h5-font-size: 1.125rem !default; //18px
$headings-font-weight: 700 !default;

// button colors

$mdg-orange:#f8997b;
$mdg-purple:#c097c1;
$mdg-green:#74c8b0;
$mdg-gray:#80a0ad;
$mdg-lightgray:#aaafc9;
$mdg-lightpurple:#c7a5df;
$mdg-lightred:#f38998;
$mdg-delete:#ff9a88;

//transition
$transition: 0.5s ease-in-out;