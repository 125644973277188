.ReactTags__tags {
    position: relative;
  }

  /* Styles for the input */
  .ReactTags__tagInput input.ReactTags__tagInputField,
  .ReactTags__tagInput input.ReactTags__tagInputField:focus {
    height: 36px;
    margin: 0;
    border:0;
    width: 100%;
  }

  .ReactTags__editInput {
    border-radius: 1px;
  }

  .ReactTags__editTagInput {
    display: inline-flex;
  }

  /* Styles for selected tags */
  .ReactTags__selected span.ReactTags__tag {
    border: 0;
    background: #63bcfd;
    font-size: 12px;
    display: inline-block;
    padding: 2px 5px;
    margin: 5px 5px;
    border-radius: 4px;
  }
  .ReactTags__selected span.ReactTags__tag:first-child {
    margin-left: 0px;
  }
  .ReactTags__selected a.ReactTags__remove {
    margin-left: 5px;
    cursor: pointer;
  }

  /* Styles for suggestions */
  .ReactTags__suggestions {
    position: absolute;
    top:60px;
  }
  .ReactTags__suggestions ul {
    list-style-type: none;
    box-shadow: 0.05em 0.01em 0.5em rgba(0, 0, 0, 0.2);
    background: white;
    width: 200px;
  }
  .ReactTags__suggestions li {
    border-bottom: 1px solid #ddd;
    padding: 5px 10px;
    margin: 0;
  }
  .ReactTags__suggestions li mark {
    text-decoration: underline;
    background: none;
    font-weight: 600;
  }
  .ReactTags__suggestions ul li.ReactTags__activeSuggestion {
    background: #b7cfe0;
    cursor: pointer;
  }

  .ReactTags__remove {
    border: none;
    cursor: pointer;
    background: none;
  }

  .ReactTags__selected{
      margin-top: 5px;
  }
  


  /*======================================*/
  .custm-picklist{
      display: flex;
      @include mq(lg){
        flex-direction: column;
      }
      .custm-picklist-box{
        -webkit-flex: 1 1 50%;
        -moz-box-flex: 1;
        flex: 1 1 50%;
        .p-listbox-list {
          padding: 0;
            .p-listbox-item{
              padding: 0;
              .product-item{
                padding: .3125rem 1rem;
              }
                &:focus{
                    box-shadow: none;
                }
            }
        }
      }
      .custm-picklist-buttons{
        -webkit-flex-direction: column;
        -moz-box-orient: vertical;
        -moz-box-direction: normal;
        flex-direction: column;
        -webkit-justify-content: center;
        -moz-box-pack: center;
        justify-content: center;
        display: flex;
        padding: 1.25rem;
        .p-button{
            margin-bottom: .9375rem;
            .p-button-icon{
                @include mq(lg){
                    transform: rotate(-90deg);
                  }
            }
            &:last-child{
                @include mq(lg){
                    margin-right: 0;
                  }
            }
            @include mq(lg){
                margin-bottom: 0;
                margin-right: 1.5625rem;
              }
            &:nth-child(2){
                margin-bottom: 3.125rem;
                @include mq(lg){
                    margin-bottom: 0;
                    margin-right: 3.125rem;
                  }
            }
        }
        @include mq(lg){
            flex-direction: row;
          }
      }
  }

  .mdg-picklist-header{
    padding: .75rem 1rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.mdg-picklist-searchsort{
    padding: .75rem;
    border-bottom: 0;
    position: relative;
    .p-inputtext{
        background: #fff;
        padding: 8px;
        border-radius: 4px;
        border-bottom: 0;
    }
    .mdg-picklist-searchbar{
        display: flex;
        justify-content: space-between;
        align-items: center;
        .search-textbar{
            width: 95%;
            input{
                width: 100%;
                height: 36px;
                border: 0;
                border-radius: 6px;
                padding-left: 15px;
                outline: none;
                &:focus{
                    border: 0;
                }
            }
        }
        
    }
    .sort-icons{
        line-height: initial;
        position: absolute;
        right: 9px;
        top: 18px;
        i{
            font-size: 18px;
        }
    }
}

