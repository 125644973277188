.box-loader .spin-loader-overlay {
 // background: rgba(255, 255, 255, 0.8);
 position: absolute;
 left: 0;
 right: 0;
 top: 0;
 bottom: 0;
 z-index: 1030;
 width: 100%;
 height: 100%;
}

.box-loader .spin-loader {
 position: absolute;
 z-index: 1050;
 left: 50%;
 top: 50%;
 padding: 0;
 -webkit-transform: translate(-50%, -50%);
 -moz-transform: translate(-50%, -50%);
 -ms-transform: translate(-50%, -50%);
 -o-transform: translate(-50%, -50%);
 transform: translate(-50%, -50%);
 margin: 0;
}

.fixed-loader .spin-loader-overlay {
 // background: rgba(255, 255, 255, 0.8);
 position: fixed;
 left: 0;
 right: 0;
 top: 0;
 bottom: 0;
 z-index: 1030;
 width: 100%;
 height: 100%;
}

.fixed-loader .spin-loader {
 position: fixed;
 z-index: 1050;
 left: 50%;
 top: 50%;
 padding: 0;
 -webkit-transform: translate(-50%, -50%);
 -moz-transform: translate(-50%, -50%);
 -ms-transform: translate(-50%, -50%);
 -o-transform: translate(-50%, -50%);
 transform: translate(-50%, -50%);
 margin: 0;
}

.full-react-loader {
 display: block;
 position: fixed;
 z-index: 100000;
 top: 0;
 bottom: 0;
 left: 0;
 right: 0;
}
.file-downloading {
 position: absolute;
 z-index: 1050;
 left: 48%;
 top: 30%;
 padding: 0;
 -webkit-transform: translate(-30%, -30%);
 -moz-transform: translate(-30%, -30%);
 -ms-transform: translate(-30%, -30%);
 -o-transform: translate(-30%, -30%);
 transform: translate(-30%, -30%);
 margin: 0 auto;
}