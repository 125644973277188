.profile-detail{
 .profile-label{
  position: relative;
    display: inline-block;
    width: 2em;
    height: 2em;
    line-height: 2em;
    vertical-align: middle;
    background: #000;
    border-radius: 100%;
    text-align: center;
    margin-right: 10px;
    i{
     color: $white;
    }
 }
 label{
  font-size: 14px;
 }
}

.bordered-formcontrol{
 border-width: 0 0 1px 0;
 border-color: 1px solid #d8d8d8;
 border-radius: 0;
 padding-left: 0;
 font-size: 14px;
 &:focus{
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.2), 0 0 8px rgba(102, 175, 233, 0.6);
 }
}